.footer-container {
    color: white;
    background-color: #343a40;
    padding: 2rem 0 4rem 0;
  }

.footer {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-evenly;
}

.footer-heading {
  display: flex;
  flex-direction: column;
  margin-right: 4rem;
}


.footer-heading h5 {
  margin-bottom: 1rem;
}

.footer-heading a {

    color: white;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-heading a:hover {
  color: cyan;
  transition: 0.3s ease-out;
}

.footer-copyright  {
  text-align: center;
}

.footer-copyright h2 {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 480px) {
  .footer {
    justify-content: space-between;
  }
  .footer-heading {
  margin-right: 2rem;
  margin-bottom: 2rem;
}
}
