$sidebar-bg-color: #007bff;

$submenu-bg-color: #007bff;
$submenu-bg-color-collapsed: #007bff;
$icon-bg-color: #007bff;

$sidebar-color: white !default;
$highlight-color: cyan !default;


@import '~react-pro-sidebar/dist/scss/styles.scss';

