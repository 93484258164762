.application-container
{   display: flex;
    flex: 1 0;
    min-width: 100%;
    flex-direction: row;
    align-items: left;
    text-align: left;
}

.app-content-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width : 100%;
    margin-bottom: 20px;
}

.app-snippet-list-container {
    margin-top: 40px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    flex-grow:1;
}

.app-snippet-container {
    width: 60%;
    margin: auto;
 }

 .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #007bff;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display:none;
  }

 @media (max-width: 768px) {
    .btn-toggle {
      display: flex;
      margin-top:15px;
      margin-left:15px;
    }
    .app-snippet-container {
      width: 80%;
   }
  }
