

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  align-items: center;

  text-align: center;
}

.page-header {
  display: flex;
  flex-direction: column;
  height: 50px;
  height: 100% !important;
  top: 0;
  position:sticky;
  z-index: 4444;
}

.content-wrap {
  flex: 1;
}

.App-logo {
  height: 45px;
  width : 45px;
}



.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



#page-body {
  max-width: 700px;
  margin: auto;
  padding-top: 64px;
  text-align: center;
}
