


.search-label {
    display: block !important;
}

.snippet-list-row-buttons {
  display: flex;
  justify-content: space-start;
  align-items: flex-start;
}

.show.react-bs-table-sizePerPage-dropdown ul:last-child {
  display: block;
}
