


.card{
   margin-top : 40px;
   margin-bottom: 40px;
   text-align: center;
}

.card-text{
  white-space: pre-wrap;
  text-align: left;
}

.vl {
   border-left: 6px solid blueviolet;
   height: 25px;
   position: relative;
   left: 50%;
   margin-left: -3px;
   top: 0;
 }

 .arrow-icon
 {
    color: blueviolet;
    text-align: center;
 }

 .btn-group.special {
   display: flex;
 }
 .special .btn {
   flex: 1
 }

 .modal-body{
  white-space: pre-wrap;
  text-align: left;
}

.modal-md-dialog {
  padding-top: 50px;
}

